<template>
  <div
    style="display: flex; background-color: #b0d9ff"
    @keydown.enter="loginUser"
  >
    <v-row class="main-row">
      <v-col class="login-col">
        <div class="language-row">
          <LanguageDropDown />
        </div>
        <v-col class="form-col">
          <div class="header-row">
            <div class="login-decoration-row" />
            <v-row class="title-row">
              <h1 class="login-title">
                {{ $t("Login") }}
              </h1>
            </v-row>
            <BaseInput
              id="email-input"
              v-model="email"
              :auto-focus="isSafari"
              :auto-complete="'username'"
              placeholder="E-mail"
              icon-left="email.svg"
              :spaces-removal="true"
            />
            <BaseInput
              id="password-input"
              v-model="password"
              :auto-complete="'current-password'"
              :auto-focus="isSafari"
              :placeholder="$t('Password')"
              type="password"
              icon-left="lock.svg"
              style="margin-top: 8px"
            />
            <v-row class="login-row">
              <button
                @click="loginUser"
                name="login-button"
                type="submit"
                class="login-button content-semibold"
              >
                {{ $t("Login") }}
              </button>
              <!--              <span class="forgot-password-row">
                {{$t("ForgotPassword")}}
              </span>-->
            </v-row>
            <v-row v-if="responseMessage" class="error-row">
              <p>{{ responseMessage }}</p>
            </v-row>
          </div>
        </v-col>
        <div class="footer-row">
          <a href="https://www.runproperty.com/">
            <img src="../assets/images/rpFullLogo.svg" alt="" />
          </a>
          <p style="opacity: 0.6; font-size: 12px">
            ver. {{ formatVersion(buildVersion) }}
          </p>
        </div>
      </v-col>
      <v-col
        v-bind:class="{
          'image-col': !isUusMaa,
          'image-col-uusmaa': isUusMaa,
          'image-col-kaanon': isKaanon,
          'image-col-domus': isDomus,
        }"
      >
        <img
          class="company-logo"
          v-if="isUusMaa || isKaanon || isDomus"
          :src="companyLogoUrl"
          alt="Company Logo"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import BaseInput from "@/components/common/BaseInput";
import LanguageDropDown from "@/components/common/LanguageDropdown";
import Bowser from "bowser";

export default {
  name: "Login",
  components: {
    LanguageDropDown,
    BaseInput,
  },
  data() {
    return {
      email: "",
      password: "",
      authError: null,
      tryingToLogIn: false,
      responseMessage: null,
      isSafari: false,
    };
  },
  created() {
    if (this.user) {
      this.redirectAfterLogin();
    }
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (browser.getBrowserName() === "Safari") {
      this.isSafari = true;
    }
    if (this.$route.params.email && this.$route.params.password) {
      this.email = this.$route.params.email;
      this.password = this.$route.params.password;
      this.loginUser();
    }
  },
  computed: {
    ...mapGetters(["user", "buildVersion"]),
    isUusMaa() {
      return window.location.hostname === "uusmaa.runproperty.com";
    },
    isKaanon() {
      return window.location.hostname === "kaanon.runproperty.com";
    },
    isDomus() {
      return window.location.hostname === "domus.runproperty.com";
    },
    companyLogoUrl() {
      if (this.isUusMaa) {
        return require("@/assets/images/uusmaa_logo.svg");
      }
      if (this.isKaanon) {
        return require("@/assets/images/kaanon-logo.svg");
      }
      if (this.isDomus) {
        return require("@/assets/images/domus-logo.svg");
      }
      return null;
    },
    placeholders() {
      return process.env.NODE_ENV === "production"
        ? {}
        : {
            username: 'Use "admin" to log in with the mock API',
            password: 'Use "password" to log in with the mock API',
          };
    },
  },
  methods: {
    ...mapActions([
      "login",
      "getCustomers",
      "changeFilterCondition",
      "getUsers",
      "getUser",
      "getGroups",
    ]),
    async loginUser() {
      try {
        const user = {
          email: this.email,
          password: this.password,
        };
        await this.login(user);

        await this.redirectAfterLogin();

        /* location.reload(true);*/
      } catch (error) {
        console.log(error);
        this.responseMessage = "Login failed";
        setTimeout(() => {
          this.responseMessage = null;
        }, 3000);
      }
    },
    async redirectAfterLogin() {
      // let loginPath = sessionStorage.getItem("redirectPath");
      let loginPath = null;
      if (!loginPath) {
        if (
          this.user.companyId === "7QxJBWjXZYrUUBq5OuZo" ||
          this.user.companyId === "_dw_1O8jj0QTYIRahjbF"
        ) {
          loginPath = "/property-owner";
        } else {
          if (this.user.config) {
            loginPath = "/listings";
          } else {
            loginPath = "/projects";
          }
        }
      }
      sessionStorage.removeItem("redirectPath");
      await this.getUsers();
      await this.$router.replace(loginPath);
      if (this.user.access.accessLevel === "group_admin") {
        await this.getUser({
          userId: this.user._id,
          arrayOfFieldKeys: ["access"],
        });
      }
      await this.getGroups();
    },
    formatVersion(version) {
      const newDate = new Date(version);
      return moment(newDate).format("DDMM-HHmm");
    },
  },
};
</script>

<style lang="scss" scoped>
.main-row {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #b0d9ff;
}

.language-row {
  position: absolute;
  left: 56px;
  top: 36px;
  /*display: flex;
  flex-direction: row;
  width: 50%;
  margin-bottom: 6vh;*/
}

.login-col {
  display: flex;
  flex-direction: column;
  max-width: 48%;
  justify-content: center;
  padding: 24px 36px;
  align-items: center;
}

.login-row {
  margin-top: 16px !important;
}

.password-row {
  margin-top: 8px !important;
}

.form-col {
  padding: 0 12px 12px 12px;
  background-color: white;
  border-radius: 12px;
  width: 24vw;
  min-width: 300px;
  max-width: 544px;
  //min-height: 460px;
  max-height: 640px;
  margin-top: 10%;
  margin-bottom: 16%;
}

.header-row {
  width: 54%;
  min-width: 200px;
  margin: 0 auto;
}

.login-decoration-row {
  background-color: #4c8dd9;
  height: 20px;
  border-radius: 0 0 12px 12px;
}

.title-row {
  margin-top: clamp(20px, 14vh, 110px);
  margin-bottom: 3vh;
}

.login-title {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  font-family: "Geomanist", sans-serif !important;
}

.email-row {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.login-button {
  text-transform: none;
  width: 100%;
  padding: 12px 16px;
  background-color: black !important;
  color: white !important;
  border-radius: 12px;
  text-align: center !important;
}

.forgot-password-row {
  cursor: pointer;
  font-family: "Inter", sans-serif;
  text-decoration: underline;
  display: table;
  margin-top: 2vh;
  margin-bottom: 2vh;
  text-align: center;
  width: 100%;
  font-size: 14px;
}

.error-row {
  display: flex;
  padding: 16px;
  max-height: fit-content;
  background-color: rgba(255, 0, 0, 0.4);
}

.logo-wrapper {
  cursor: pointer;
}

.logo {
  max-width: 128px;
  margin-top: 64px;
}

.footer-row {
  position: absolute;
  left: 56px;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}

.image-col {
  background-color: #f4f5f7;
  height: 100vh;
  background-image: url("../assets/images/login-general.png");
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.image-col-uusmaa {
  background-color: #f4f5f7;
  height: 100vh;
  background-image: url(https://uusmaa.ee/wp-content/uploads/2019/08/AdobeStock_271403301-1-1024x674.jpeg);
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.image-col-kaanon {
  background-color: #f4f5f7;
  height: 100vh;
  background-image: url("../assets/images/kaanon-background.png");
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.image-col-domus {
  background-color: #f4f5f7;
  height: 100vh;
  background-image: url("../assets/images/domus-background.jpg");
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.company-logo {
  position: absolute;
  top: 100px;
  max-height: 94px;
}

@media screen and (max-width: 900px) {
  .main-row {
    height: 110vh;
    max-width: 900px;
  }
  .form-col {
    max-height: 540px;
  }
  .footer-row {
    display: none;
  }
  .image-col {
    display: none;
  }
}

.title-row {
  padding-left: 0;
}
</style>
